<template>
  <div class="my-5 container">

    <!-- Title -->
    <h1 class="mb-4 text-center">
      {{ $t('railVehicle.predictiveTitle') }}
    </h1>

    <!-- First -->
    <b-row class="mb-5">

      <b-col
        lg="6"
        md="12"
      >
        <b-img-lazy
          :src="images[0].image"
          alt="Train Image"
          class="mb-4 w-100"
        />
      </b-col>

      <b-col
        lg="6"
        md="12"
      >
        <p>
          {{ $t('railVehicle.text1') }}
        </p>
      </b-col>

    </b-row>

    <!-- Second -->
    <b-row>

      <b-col
        class="mb-3"
        lg="6"
        md="12"
      >
        <p style="color: #ff8f00 !important">
          {{ $t('railVehicle.text2') }}
        </p>

        <p>
          {{ $t('railVehicle.text3') }}
        </p>
      </b-col>

      <b-col
        lg="6"
        md="12"
      >
        <b-img-lazy
          :src="images[1].image"
          alt="Train Image"
          class="mb-4 w-100"
        />
      </b-col>

    </b-row>

  </div>
</template>

<script>

import {
  BRow,
  BCol,
  BImgLazy,
} from 'bootstrap-vue'

export default {
  name: 'Predictive',
  components: {
    BRow,
    BCol,
    BImgLazy,
  },
  props: {
    images: {
      type: Array,
      default: null,
      required: true,
    },
  },
}
</script>
