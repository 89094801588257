<template>
  <div
    class="my-5 container text-center video"
  >

    <!-- Title -->
    <h3 class="mb-4">
      {{ $t('railVehicle.videoTitle') }}
    </h3>

    <!-- Video -->
    <div
      class="embed-responsive embed-responsive-16by9"
    >
      <iframe
        class="embed-responsive-item"
        src="https://www.youtube.com/embed/SVVBC23gvU0?rel=0"
        allowfullscreen
        :title="$t('railVehicle.videoTitle')"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'ImasVideo',
}
</script>

<style scoped>
  .video {
    width: 40%;
  }

  @media (min-width: 1200px) and (max-width: 1400px){
    .video {
      width: 80% !important;
    }
  }

  @media (min-width: 700px) and (max-width: 1200px){
    .video {
      width: 80% !important;
    }
  }

  @media (max-width: 700px){
    .video {
    width: 100%;
  }
  }
</style>
