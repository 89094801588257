<template>
  <div class="railvehicle">
    <!-- Banner -->
    <banner
      :image="data.bannerImage"
      :video="data.bannerVehicleVideo"
      :title="$t('railVehicle.bannerTitle')"
      height="600"
    />

    <!-- Predictive maintenance -->
    <predictive :images="data.trainImages" />

    <!-- Video -->
    <imas-video />

    <!-- Main Functionalities -->
    <main-functionalities :images="data.trainImages" />
  </div>
</template>

<script>

import Banner from 'Components/Common/Banner.vue'
import Predictive from 'Components/RailVehicle/Predictive.vue'
import MainFunctionalities from 'Components/RailVehicle/MainFunctionalities.vue'
import ImasVideo from 'Components/RailVehicle/ImasVideo.vue'
import MyData from 'Data/imas.json'

export default {
  name: 'RailVehicle',
  components: {
    Banner,
    Predictive,
    MainFunctionalities,
    ImasVideo,
  },
  data() {
    return {
      data: MyData.data,
    }
  },
}
</script>
