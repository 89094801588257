<template>
  <div class="my-5 container">

    <!-- Title -->
    <h1 class="mb-4">
      {{ $t('railVehicle.functionalitiesTitle') }}
    </h1>

    <!-- First -->
    <b-row class="mb-5">

      <b-col
        lg="6"
        md="12"
      >
        <b-img-lazy
          :src="images[2].image"
          alt="Train Image"
          class="mb-4 w-100"
        />

        <b-img-lazy
          :src="images[3].image"
          alt="Train Image"
          class="mb-4 w-100"
        />
      </b-col>

      <b-col
        lg="6"
        md="12"
      >
        <div
          v-for="item in $t('railVehicle.functionalities')"
          :key="item.number"
        >
          <h4 style="color: #ff8f00">
            {{ item.number }}
          </h4>

          <p>
            {{ item.text }}
          </p>
        </div>

      </b-col>

    </b-row>
  </div>
</template>

<script>

import {
  BRow,
  BCol,
  BImgLazy,
} from 'bootstrap-vue'

export default {
  name: 'MainFunctionalities',
  components: {
    BRow,
    BCol,
    BImgLazy,
  },
  props: {
    images: {
      type: Array,
      default: null,
      required: true,
    },
  },
}
</script>
